import './Note.scss';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { taskStatusToClass } from '@he-novation/config/constants/projects.constants';
import type { Note as NoteType } from '@he-novation/config/types/note.types';
import { Button } from '@he-novation/design-system/components/buttons/Button/Button';
import Icon from '@he-novation/icons';
import { projectTasksLink } from '@he-novation/paths/herawFrontUris';
import cn from 'classnames';
import { useSetAtom } from 'jotai';
import { selectedNoteAtom } from '../../../atoms/note-atoms';
import NoteOrTaskContent from '../../Notes/NoteOrTaskContent/NoteOrTaskContent';

import { NOTE_PANEL } from '$constants/constants.sidePanels';
import { setFilter } from '$redux/route/routeActions';
import { openPanel } from '$redux/sideMenu/sideMenuActions';
type NoteProps = {
    note: NoteType & { imageData?: { min: string; max: string } };
    currentUserUuid?: string;
    currentProjectUuid?: string;
    fileThumbnail: string;
    isPublicFile?: boolean;
};

export function Note({
    note,
    isPublicFile,
    currentUserUuid,
    currentProjectUuid,
    fileThumbnail
}: NoteProps) {
    const lastComment = note.comments[0];
    const dispatch = useDispatch();
    const setSelectedNote = useSetAtom(selectedNoteAtom);

    const openNote = useCallback(() => {
        dispatch(openPanel(NOTE_PANEL, { isPublicFile }));
        setSelectedNote(note);
        dispatch(setFilter('selected_note', note.uuid));
    }, [note, isPublicFile]);

    const thumbnail =
        note.assets.find((a) => a.type === 'note' && a.url)?.url ||
        note.imageData?.min ||
        fileThumbnail;

    return (
        <section
            className={cn('c-note', {
                'is-mine': lastComment.user?.uuid === currentUserUuid
            })}
        >
            <div className="note-content" onClick={openNote}>
                {note.task && (
                    <Button
                        className={`task-button ${taskStatusToClass(note.task.status)}`}
                        icon="task-done"
                        href={projectTasksLink(currentProjectUuid!, note.task.uuid)}
                    />
                )}
                <NoteOrTaskContent
                    actions={[]}
                    attachments={lastComment.assets}
                    description={lastComment.content}
                    header={{
                        thumbnail,
                        metadata: note.metadata,
                        type: note.type,
                        uuid: note.uuid
                    }}
                    projectUuid={currentProjectUuid}
                    task={note.task}
                    user={lastComment.user}
                    tags={lastComment.tags}
                />

                <button className="btn-response" onClick={openNote}>
                    <span>{note.comments.length}</span>
                    <Icon icon="speech-bubble" />
                </button>
            </div>
        </section>
    );
}
