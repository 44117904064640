import { connect } from 'react-redux';
import { __ } from '@he-novation/design-system/utils/i18n';
import { compose, lifecycle, withProps } from 'recompose';
import SidePanelAddNote from './SidePanelAddNote';

import { SidePanelAddTaskPayload } from '$components/SidePanel/SidePanelAddNote/SidePanelAddTaskContainer';
import { CREATE_NOTE_PANEL, NOTES_PANEL } from '$constants/constants.sidePanels';
import { areaSelectionReset } from '$redux/content/areaSelection/areaSelectionActions';
import { pause } from '$redux/content/file/fileActions';
import { fileProjectUuidSelector } from '$redux/content/file/fileSelectors';
import { openPanel } from '$redux/sideMenu/sideMenuActions';

export type SidePanelAddNotePayload = {
    isPublicFile?: boolean;
};

export const actionSidePanelAddNoteOpen = (payload: SidePanelAddNotePayload) =>
    openPanel(CREATE_NOTE_PANEL, payload);

const mapDispatchToProps = (
    dispatch,
    { payload: { isPublicFile } = {} }: { payload: SidePanelAddNotePayload }
) => ({
    goBackToNotes: (password?: string | null) => {
        dispatch(openPanel(NOTES_PANEL, { isPublicFile, password }));
        dispatch(areaSelectionReset());
    },
    pauseVideo: () => dispatch(pause())
});

export default compose(
    connect(fileProjectUuidSelector, mapDispatchToProps),
    withProps(
        ({
            fileProjectUuid,
            payload: { isPublicFile } = {}
        }: {
            fileProjectUuid: string;
            payload: SidePanelAddTaskPayload;
        }) => ({
            isNote: !(fileProjectUuid && isPublicFile),
            title: __('REACT_NEW_COMMENT')
        })
    ),
    lifecycle({
        componentDidMount() {
            this.props.pauseVideo();
        }
    })
)(SidePanelAddNote);
