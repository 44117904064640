import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Note } from '@he-novation/config/types/note.types';
import { applyNoteFilters } from '@he-novation/config/utils/noteUtils';
import { useAtomValue } from 'jotai';
import { displayAllVersionsAtom } from '../../atoms/note-atoms';
import NotesFilters from './NotesFilters/NotesFiltersContainer';
import NotesList from './NotesList/NotesListContainer';

import { fileHighlightedVersionSelector } from '$redux/content/file/fileSelectors';

type NotesWithFiltersProps = {
    displayFilters: boolean;
    filters: any;
    setFilters: (filters: any) => void;
    notes: Note[];
    isPublicFile?: boolean;
    sorters: string[];
    sorter: string;
    setSorter: (sorter: string) => void;
};
const NotesWithFilters = ({
    displayFilters,
    filters,
    setFilters,
    notes,
    isPublicFile,
    sorters,
    sorter,
    setSorter
}: NotesWithFiltersProps) => {
    const { fileHighlightedVersion } = useSelector(fileHighlightedVersionSelector);
    const displayAllVersions = useAtomValue(displayAllVersionsAtom);

    const filteredNotes = useMemo(() => {
        const sortedNotes = notes.sort(
            (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
        );

        const filteredNotes = displayAllVersions
            ? sortedNotes
            : sortedNotes.filter(({ file }) => file.version === fileHighlightedVersion);
        return applyNoteFilters(filters, filteredNotes);
    }, [notes, fileHighlightedVersion, displayAllVersions, filters]);

    return (
        <div className="c-notes-with-filters">
            {displayFilters && (
                <NotesFilters
                    notes={notes}
                    onFilter={setFilters}
                    isPublicFile={isPublicFile}
                    sorters={sorters}
                    sorter={sorter}
                    setSorter={setSorter}
                />
            )}
            <NotesList notes={filteredNotes} isPublicFile={isPublicFile} sorter={sorter} />
        </div>
    );
};

export default NotesWithFilters;
