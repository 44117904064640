import './DatesNotesList.scss';
import React from 'react';
import type { Note as NoteType } from '@he-novation/config/types/note.types';
import { __ } from '@he-novation/design-system/utils/i18n';

import Note from '$components/Notes/Note/NoteContainer';
import { getLocalizedDate, toRoundDate } from '$helpers/datetime';

type DatesNotesListProps = {
    notes: NoteType[];
    locale: string;
    isPublicFile?: boolean;
};
const DatesNotesList = ({ notes: _notes, locale, isPublicFile }: DatesNotesListProps) => {
    const dates = _notes
        .map(({ comments, ...note }) => ({
            date: toRoundDate(comments[0]?.created || new Date()),
            ...note,
            comments
        }))
        .reduce((acc, { date, ...note }) => {
            let index = acc.findIndex((d) => d.date.getTime() === date.getTime());
            if (index === -1) {
                index = acc.length;
                acc.push({
                    date,
                    notes: []
                });
            }

            acc[index].notes.push(note);
            return acc;
        }, [] as { date: Date; notes: NoteType[] }[]);

    return (
        <ul className="c-dates-notes-list">
            {(!dates || dates.length === 0) && <li className="empty">{__('NOTES_EMPTY')}</li>}

            {dates
                .sort((a, b) => {
                    return b.date.getTime() - a.date.getTime();
                })
                .map(({ date, notes }, i) => (
                    <li key={date.getTime() + i}>
                        <span className="note-date">
                            {' '}
                            •{' '}
                            {getLocalizedDate(date, locale, {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric'
                            })}
                        </span>
                        <ul>
                            {notes
                                .sort(
                                    (a, b) =>
                                        new Date(b.comments[0].created).getTime() -
                                        new Date(a.comments[0].created).getTime()
                                )
                                .map((note) => (
                                    <li
                                        key={note.uuid}
                                        id={`note-list-${note.uuid}`}
                                        className="note-container"
                                    >
                                        <Note
                                            note={note}
                                            key={note.uuid}
                                            isPublicFile={isPublicFile}
                                        />
                                    </li>
                                ))}
                        </ul>
                    </li>
                ))}
        </ul>
    );
};

export default DatesNotesList;
